import { compile, match as matchPath } from 'path-to-regexp';

import config from '@/lib/config';
import { stripTrailingSlash } from '@/lib/stringHelpers';

/**
 * A shared place to access full URL path patterns
 */
export const routes = {
  index: '/',
  spaceIndex: '/spaces',
  spaceMap: '/spaces/all/map',
  spaceList: '/spaces/all/list',
  spaceDetail: '/spaces/:spaceSlug',
  spaceReserve: '/spaces/:spaceSlug/reserve',
  spaceReserveConfirm: '/spaces/:spaceSlug/reserve/confirm',

  roomIndex: '/rooms',
  roomMap: '/rooms/all/map',
  roomDetail: '/rooms/:spaceSlug/:roomSlug',
  roomList: '/rooms/all/list',
  // Used when inside roomDetail and change filter to deskpass mode
  roomDetailSpaces: '/rooms/:spaceSlug/:roomSlug/spaces',
  roomReserve: '/rooms/:spaceSlug/:roomSlug/reserve',
  roomReserveConfirm: '/rooms/:spaceSlug/:roomSlug/reserve/confirm',

  reservationIndex: '/reservations',
  reservationActiveIndex: '/reservations/active',
  reservationPastIndex: '/reservations/past',

  reservationShow: '/reservations/:type/:reservationId',
  reservationInfo: '/reservations/:type/:reservationId/info',
  reservationRules: '/reservations/:type/:reservationId/rules',
  reservationCancel: '/reservations/:type/:reservationId/cancel',
  reservationModify: '/reservations/:type/:reservationId/modify',
  reservationModifyConfirm: '/reservations/:type/:reservationId/modify/confirm',
  reservationExtend: '/reservations/:type/:reservationId/extend',
  reservationExtendConfirm: '/reservations/:type/:reservationId/extend/confirm',
  reservationAddToCalendar: '/reservations/:type/:reservationId/calendar',
  reservationCheckIn: '/reservations/deskpass/:reservationId/checkin',
  guestEdit: '/reservations/deskpass/:reservationId/guests/:guestId',
  guestAdd: '/reservations/deskpass/:reservationId/guests/add',
  guestInvitationIndex: '/reservations/deskpass/guest/:hash',

  signup: '/signup',
  // TODO refactor the team signup and remove these routes
  //
  // Currently in use by the teams flow
  teamSignup: '/signup/teams/:inviteHash',
  teamSignupProfile: '/signup/profile/teams/:inviteHash',
  signupRules: '/signup/rules',
  signupDone: '/signup/done',

  accountShow: '/account',
  accountReceiptIndex: '/account/receipts',
  accountCreditCardEdit: '/account/credit-card/edit',

  rules: '/rules',
  login: '/login',
  logout: '/logout',
  community: `${config.MARKETING_SITE_URL}/community/welcome`,
  confirmEmail: '/confirm-email/:hash',
  breatherSetPassword: '/breather-set-password',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:hash',
  more: '/more',
  help: 'https://help.deskpass.com',
  iosApp: 'https://itunes.apple.com/us/app/deskpass/id1006748184',
  googleApp:
    'https://play.google.com/store/apps/details?id=com.desktimeapp.deskpass',
  benefits: 'https://www.deskpass.com/benefits',
  about: 'https://www.deskpass.com/about',
  covidSafetyPledge: 'https://www.deskpass.com/covid-safety-pledge',
  blog: 'https://www.deskpass.com/resources',
  tos: 'https://www.deskpass.com/legal/terms-of-use',
  privacyPolicy: 'https://www.deskpass.com/legal/privacy-policy',
  deskpassSupportEmail: 'mailto:support@deskpass.com',

  twitter: 'https://twitter.com/deskpasshq',
  facebook: 'http://www.facebook.com/deskpass',
  instagram: 'https://www.instagram.com/deskpass/',

  stripeIdentityInfo:
    'https://support.stripe.com/questions/common-questions-about-stripe-identity',

  // Wiki
  wikiWhyNeedCreditCard:
    'http://help.deskpass.com/frequently-asked-questions/i-m-interested-how-does-deskpass-work/do-i-need-a-credit-card-for-my-free-trial',
  wikiDeskpassHourlyDifference:
    'http://help.deskpass.com/frequently-asked-questions/i-m-interested-how-does-deskpass-work/whats-the-difference-between-deskpass-and-hourly',

  forbidden: '/error/forbidden',
};

const googleMapsRoute = (address) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address,
  )}`;
};

const route = (name, params) => {
  const path = routes[name];
  /* eslint-disable */
  if (!path) console.error(`No route found named "${name}"`);
  /* eslint-enable */
  if (typeof params === 'object') {
    let toPath = compile(path);
    let formattedPath = toPath(params);
    return formattedPath;
  } else {
    return path;
  }
};

/**
 * Given a route name and current params along with a route to match (usually
 * the current path), check if the interpolated toMatch route matches the
 * uninterpolated route indentified by name.
 *
 * @param {String} name    Name of route to check for match
 * @param {Object} params  Params to interpolate route with
 * @param {String} toMatch URL path to check for match with route
 *
 * @return {Boolean} Returns whether or not toMatch is a match for route(name)
 */
const routeMatch = (name, params, toMatch) => {
  // Make sure passed toMatch URL doesn't have trailing slash for comparison
  // purposes
  toMatch = stripTrailingSlash(toMatch);

  // Then simply whether or not rendered route matches toMatch URL
  return route(name, params) === toMatch;
};

const absoluteRoute = (name, params) =>
  stripTrailingSlash(config.SERVER_URL) + route(name, params);

const routePath = (name) => routes[name];

const pathMatchRouteName = (path = '', name, exact = true) => {
  // Remove query params to test if the route exists
  let pathname = path?.split('?')[0];

  // TODO Hacks like that are necessary while path-to-regex
  // do not support regex negation operations...
  if (
    name === 'roomDetail' &&
    (pathname === '/rooms/all/list' || pathname === '/rooms/all/map')
  ) {
    return false;
  }

  return !!matchPath(routes[name], { decode: decodeURIComponent, end: exact })(
    pathname,
  );
};

const pathExists = (path) => {
  return Object.keys(routes).some((key) => {
    return pathMatchRouteName(path, key);
  });
};

const getNameForPath = (path) => {
  let match = Object.keys(routes)
    .reverse()
    .find((key) =>
      matchPath(routes[key], { decode: decodeURIComponent, end: true })(path),
    );
  return match ? match : false;
};

const getEquivilentSpaceRoomRoute = (pathname) => {
  if (!pathname) return null;

  return pathname.startsWith('/space')
    ? pathname.replace('space', 'room')
    : pathname.replace('room', 'space');
};

export {
  route,
  getEquivilentSpaceRoomRoute,
  googleMapsRoute,
  absoluteRoute,
  pathMatchRouteName,
  getNameForPath,
  routeMatch,
  routePath,
  pathExists,
};

export default route;
