import styled, { css } from 'styled-components';

import { loadingAnimation } from '@/styles/js/utils/animation';
import eases from '@/styles/js/utils/eases';
import { absolute as positionAbsolute } from '@/styles/js/utils/position';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';

/**
 * LodingContent Placement Style
 * @param {String} placement - `neutral | inline | cover`
 */
const getPlacementStyles = ({ theme, placement }) =>
  ({
    neutral: css`
      &::before {
        border-right-color: ${theme.colors.ruleDark};
      }
    `,
    inline: css`
      min-height: 4rem;
      display: inline-block;
      width: auto;
    `,
    cover: css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: rgb(255 255 255 / 90%);
      z-index: 999;
    `,
  }[placement]);

/**
 * Styled LoadingContent
 * ---
 * Displays a loading indicator while waiting for content to display.
 *
 * props optional
 * - `placement`
 *    - `neutral`
 *    - `inline`
 *    - `cover`
 * - `small`
 */
export const StyledLoadingContent = styled.div`
  position: relative;
  width: 100%;
  min-height: 50vh;

  &::before {
    ${pseudoAbsolute}
    ${positionAbsolute}

    margin: auto;
    width: 4rem;
    height: 4rem;
    border: ${({ theme }) => '3px solid '.concat(theme.colors.rule)};
    border-radius: 100%;
    border-right-color: ${({ theme }) => theme.colors.primary};
    animation-name: ${loadingAnimation};
    animation-duration: 0.5s;
    animation-timing-function: ${eases['linear']};
    animation-iteration-count: infinite;
    will-change: transform;
  }

  ${getPlacementStyles}

  ${({ small }) =>
    small &&
    css`
      height: 2.4rem;
      width: 2.4rem;
    `}
`;

/**
 * Displays a loading indicator while waiting for content to display.
 */
const LoadingContent = ({ backgroundColor, ...otherProps }) => {
  const style = {
    // This style lint validation sucks with react inline styles
    // stylelint-disable-next-line
    backgroundColor,
  };

  return <StyledLoadingContent style={style} {...otherProps} />;
};

export default LoadingContent;
